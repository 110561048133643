<template>
    <div class="admin-index">
        <navigationBar @buttonClickEmit0="addAdmin" :buttonList="buttonList" />

        <div>
            <!--标签页-->
            <div style="width: 9.3%; ">
                <template>
                    <el-tabs v-model="type_title" @tab-click="handleClick">
                        <el-tab-pane label="我发起的" name="my_order"></el-tab-pane>
                        <el-tab-pane label="待我处理" name="my_hand"></el-tab-pane>
                    </el-tabs>
                </template>
            </div>

            <!-- 搜索区 -->
            <div>
                <el-form :inline="true" :model="filters" ref="filters">
                    <el-form-item prop="name" label="标题:">
                        <el-input v-model="filters.title" placeholder="请输入标题" size="mini"></el-input>
                    </el-form-item>

                    <el-form-item prop="deptId" label="工单类型">
                        <!-- <template>
                            <el-select v-model="filters.type" placeholder="请选择" size="mini" clearable>
                                <el-option
                                        v-for="item in options"
                                        :key="item.value"
                                        :label="item.label"
                                        :value="item.value">
                                </el-option>
                            </el-select>
                        </template>-->

                        <template>
                            <el-select
                                v-model="filters.type"
                                placeholder="请选择"
                                size="mini"
                                clearable
                            >
                                <el-option
                                    v-for="item in org_type_list"
                                    :key="item.dict_key"
                                    :label="item.dict_value"
                                    :value="item.dict_key"
                                ></el-option>
                            </el-select>
                        </template>
                    </el-form-item>

                    <el-form-item prop="level" label="紧急级别">
                        <template>
                            <el-select
                                v-model="filters.level"
                                placeholder="请选择"
                                size="mini"
                                clearable
                            >
                                <el-option
                                    v-for="item in work_level_list"
                                    :key="item.dict_key"
                                    :label="item.dict_value"
                                    :value="item.dict_key"
                                ></el-option>
                            </el-select>
                        </template>
                    </el-form-item>

                    <el-form-item prop="user_name" label="发起人:">
                        <el-input v-model="filters.user_name" placeholder="请输入发起人" size="mini"></el-input>
                    </el-form-item>

                    <el-form-item prop="status" label="工单状态:">
                        <el-select v-model="filters.status" placeholder="请选择" size="mini" clearable>
                            <el-option
                                v-for="item in work_status_list"
                                :key="item.dict_key"
                                :label="item.dict_value"
                                :value="item.dict_key"
                            ></el-option>
                        </el-select>
                    </el-form-item>

                    <el-form-item>
                        <el-button type="primary" @click="doQuery()" size="mini">查询</el-button>
                    </el-form-item>
                    <el-form-item style="margin-left: 0rem;">
                        <el-button type="info" size="mini" @click="resetForm()">重置</el-button>
                    </el-form-item>
                    <span class="add-button" style="margin-top:5px;"></span>
                </el-form>
            </div>
            <!--列表-->
            <el-table
                :data="workOderList"
                border
                highlight-current-row
                :cell-style="{padding:'1px'}"
                v-loading="listLoading"
                style="width: 100%; "
            >
                <el-table-column prop="idNo" label="序号" width="70"></el-table-column>
                <el-table-column prop="title" label="标题" width="180"></el-table-column>
                <el-table-column prop="content" label="内容" width="180"></el-table-column>
                <el-table-column prop="user_name" label="发起人姓名"></el-table-column>
                <el-table-column prop="workType_str" label="工单类型"></el-table-column>
                <el-table-column prop="level" label="紧急级别">
                    <template scope="scope">
                        <p style="color: #67C23A" v-if="scope.row.level==1">普通</p>
                        <p style="color: #E6A23C" v-if="scope.row.level==2">加急</p>
                        <p style="color: #ff0000" v-if="scope.row.level==3">特急</p>
                    </template>
                </el-table-column>

                <el-table-column prop="status" label="工单状态">
                    <template scope="scope">
                        <p style="color: #ff0000" v-if="scope.row.status==1">处理中</p>
                        <p style="color: #67C23A" v-if="scope.row.status==2">已完结</p>
                        <p style="color: #E6A23C" v-if="scope.row.status==3">转发中</p>
                    </template>
                </el-table-column>

                <el-table-column prop="create_time" label="创建时间" width="200"></el-table-column>

                <el-table-column label="操作" width="250">
                    <template slot-scope="scope">
                        <el-button
                            type="primary"
                            size="mini"
                            @click="deal(scope.row)"
                            v-if="type_title == 'my_hand'"
                        >处 理</el-button>
                        <el-button
                            size="mini"
                            @click="toWorkOrderInfo(scope.row.id)"
                            type="success"
                        >详情</el-button>
                        <el-button
                            size="mini"
                            type="warning"
                            @click="forward(scope.row)"
                            v-if="type_title == 'my_hand'"
                        >转 发</el-button>
                    </template>
                </el-table-column>
            </el-table>

            <!--分页-->
            <div class="Pagination" style="text-align: right;margin-top: 10px;">
                <el-pagination
                    @current-change="handleCurrentChange"
                    :current-page.sync="pageNo"
                    :page-size="pageSize"
                    layout="total, prev, pager, next"
                    :total="this.count"
                ></el-pagination>
            </div>

            <!--转发工单弹窗-->
            <el-dialog title="转发工单" :visible.sync="forWordFormVisible" width="40%">
                <el-form ref="form" :model="form">
                    <el-form-item label="部  门" label-width="15%" prop="dept_id">
                        <div class="block">
                            <el-cascader
                                ref="cascader"
                                v-model="form.dept_id"
                                :options="deptList"
                                :props="{ checkStrictly: true }"
                                @change="change"
                                style="width:80%"
                                placeholder="请输入部门"
                                clearable
                            ></el-cascader>
                        </div>
                    </el-form-item>

                    <el-form-item label="处理人" prop="dept_id" label-width="15%">
                        <template>
                            <el-select
                                v-model="form.handId"
                                filterable
                                placeholder="请选择处理人"
                                style="width: 80%;"
                            >
                                <el-option
                                    v-for="item in adminList"
                                    :key="item.value"
                                    :label="item.label"
                                    :disabled="item.disabled"
                                    :value="item.value"
                                ></el-option>
                            </el-select>
                        </template>
                    </el-form-item>

                    <el-form-item label="处理意见" prop="content" label-width="15%">
                        <el-input
                            type="textarea"
                            v-model="form.content"
                            placeholder="请输入处理意见"
                            style="width: 80%;"
                        ></el-input>
                    </el-form-item>

                    <el-form-item label="上传图片" label-width="15%">
                        <el-upload
                            class="upload-demo"
                            :action="uploadUrl"
                            :data="uploadDate"
                            :headers="uploadHeader"
                            :on-preview="handlePreview"
                            :on-success="handleImageSuccess"
                            :on-error="uploadError"
                            :file-list="fileList"
                            list-type="picture"
                        >
                            <el-button size="small" type="primary">点击上传</el-button>
                            <div slot="tip" class="el-upload__tip">只能上传jpg/png文件，且不超过500kb</div>
                        </el-upload>
                    </el-form-item>
                </el-form>

                <div slot="footer" class="dialog-footer">
                    <el-button size="small" type="primary" @click="submitOk()">提 交</el-button>
                    <el-button size="small" @click="forWordFormVisible = false">取 消</el-button>
                </div>
            </el-dialog>

            <!--处理弹框-->
            <el-dialog title="处理工单" :visible.sync="dialogFormDeal" width="40%">
                <el-form ref="form" :model="form">
                    <el-form-item>
                        <el-form-item label="处理意见" prop="content">
                            <el-input
                                type="textarea"
                                v-model="form.content"
                                placeholder="请输入处理意见"
                                style="width: 80%;"
                            ></el-input>
                        </el-form-item>
                    </el-form-item>

                    <el-form-item label="上传图片">
                        <el-upload
                            class="upload-demo"
                            :action="uploadUrl"
                            :data="uploadDate"
                            :headers="uploadHeader"
                            :on-preview="handlePreview"
                            :on-success="handleImageSuccess"
                            :on-error="uploadError"
                            :file-list="fileList"
                            list-type="picture"
                        >
                            <el-button size="small" type="primary">点击上传</el-button>
                            <div slot="tip" class="el-upload__tip">只能上传jpg/png文件，且不超过500kb</div>
                        </el-upload>
                    </el-form-item>
                </el-form>

                <div slot="footer" class="dialog-footer">
                    <el-button size="small" type="primary" @click="dealOk()">提 交</el-button>
                    <el-button size="small" @click="dialogFormDeal = false">取 消</el-button>
                </div>
            </el-dialog>
        </div>

        <!--新建工单弹窗-->
        <el-dialog :title="titleAdmin" :visible.sync="dialogFormVisible" width="40%">
            <el-form ref="form" label-width="15%" :rules="rules" :model="form">
                <el-form-item label="标  题" prop="title">
                    <el-input
                        type="text"
                        v-model="form.title"
                        placeholder="请输入标题"
                        style="width: 80%;"
                    ></el-input>
                </el-form-item>

                <el-form-item label="内  容" prop="content">
                    <el-input
                        type="textarea"
                        v-model="form.content"
                        placeholder="请输入工单内容"
                        style="width: 80%;"
                    ></el-input>
                </el-form-item>

                <el-form-item label="工单类型" prop="content">
                    <template>
                        <el-radio v-model="form.type" label="1">报销工单</el-radio>
                        <el-radio v-model="form.type" label="2">投诉工单</el-radio>
                    </template>
                </el-form-item>

                <el-form-item label="紧急级别" prop="content">
                    <template v-for="item in work_level_list">
                        <el-radio v-model="form.level" :label="item.dict_key">{{item.dict_value}}</el-radio>
                    </template>
                </el-form-item>

                <el-form-item label="部  门" prop="deptId">
                    <div class="block">
                        <el-cascader
                            ref="cascader"
                            v-model="form.deptId"
                            :options="deptList"
                            :props="{ checkStrictly: true }"
                            @change="change"
                            style="width:80%"
                            placeholder="请输入部门"
                            clearable
                        ></el-cascader>
                    </div>
                </el-form-item>

                <el-form-item label="处理人" prop="handId">
                    <template>
                        <el-select
                            v-model="form.handId"
                            filterable
                            placeholder="请选择处理人"
                            style="width:79%"
                        >
                            <el-option
                                v-for="item in adminList"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value"
                            ></el-option>
                        </el-select>
                    </template>
                </el-form-item>

                <el-form-item label="上传图片" prop="content">
                    <el-upload
                        class="upload-demo"
                        :action="uploadUrl"
                        :data="uploadDate"
                        :headers="uploadHeader"
                        :on-preview="handlePreview"
                        :on-remove="handleRemove"
                        :on-success="handleImageSuccess"
                        :on-error="uploadError"
                        :file-list="fileList"
                        list-type="picture-card"
                    >
                        <el-button size="mini" type="primary">点击上传</el-button>
                        <div slot="tip" class="el-upload__tip">只能上传jpg/png文件，且不超过500kb</div>
                    </el-upload>
                </el-form-item>
            </el-form>

            <div slot="footer" class="dialog-footer">
                <el-button size="mini" type="primary" @click="addAdmin('form')">提 交</el-button>
                <el-button size="mini" @click="dialogFormVisible = false">取 消</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import navigationBar from "@/components/navigationBar";
import {
    findWorkOrderByHandIdAndStatus,
    handleWorkOrder,
    findAllWorkOderPage,
    addWorkOder,
    getSysDictionaryList,
} from "@/api/getBaseData";
import { getDeptInfoList, getAdminListByDeptId } from "@/api/getUcenterData";
import cookies from "@/utils/cookies/index.js";
import setting from "@/utils/config/index.js";
export default {
    name: "page",
    data() {
        return {
            count: 0,
            pageNo: 0,
            pageSize: 15,
            workOderList: [],
            listLoading: false,
            lastPage: "",

            // 表单类型标题
            type_title: "my_order",

            // 工单紧急级别字典表
            work_level_list: [],

            // 工单状态字典表
            work_status_list: [],

            // 工单类型字典表
            org_type_list: [],

            form: {
                work_id: "",
                // dept_id:'',
                departId: "",
                handId: "",
                content: "",
                id: "",
                imgList: [],
                record_id: "",
            },
            adminList: [],

            // 部门列表
            deptList: [],

            // 文件列表
            fileList: [],

            // 搜索框
            filters: {
                name: "",
                title: "",
                deptId: "",
                type: "",
                level: "",
                user_name: "",
                status: "",
            },

            my_hand_total: "",

            // 文件上传的地址
            uploadUrl: "http://114.55.126.232:7011/oss/uploadFile",
            // 文件上传头部
            uploadHeader: {
                content_type: "application/x-www-form-urlencoded",
            },
            // 文件上传参数
            uploadDate: {
                bucketName: "dh-file-gx",
                fileName: "dh-order",
            },
            // 图片路径
            dialogImageUrl: "",

            // 新建工单弹窗
            dialogFormVisible: false,
            // 处理按钮的弹窗
            dialogFormDeal: false,
            // 转发工单的弹窗
            forWordFormVisible: false,

            // 用户信息
            userInfo: {},

            // 弹窗标题
            titleAdmin: "",
            // 弹窗表单的权限
            rules: {
                title: [
                    { required: true, message: "请输入标题", trigger: "blur" },
                ],
                deptId: [
                    { required: true, message: "请选择部门", trigger: "blur" },
                ],
                handId: [
                    {
                        required: true,
                        message: "请选择处理人",
                        trigger: "blur",
                    },
                ],
                content: [
                    { required: true, message: "请输入内容", trigger: "blur" },
                ],
            },
            //页头框中的按钮组
            buttonList: [
                {
                    backgroundColor: "#52D1AF",
                    icon: "/static/img/add.png",
                    text: "添加工单",
                },
            ],
        };
    },
    mounted() {
        // this.doQuery();
        let uid = cookies.get("uid");
        this.userInfo = JSON.parse(localStorage.getItem("userInfo" + uid));
        this.getDeptInfoList();
        this.findMyWorkOrder();
        this.getWorkLevelDic();
        this.getWorkStatusDic();
        this.getorgTypeDic();
    },
    components: {
        navigationBar,
    },
    methods: {
        handleCurrentChange: function (pageNo) {
            this.pageNo = pageNo;
            this.doQuery();
        },
        doQuery(type) {
            let uid = cookies.get("uid");
            let para = {
                pageNo: this.pageNo,
                pageSize: this.pageSize,
                hand_id: uid,
                status: 1,
            };
            this.listLoading = true;
            findWorkOrderByHandIdAndStatus(para).then(
                (resole) => {
                    let res = resole.data;
                    this.workOderList = res.list;
                    this.count = res.total;
                    for (var i = 0; i < this.workOderList.length; i++) {
                        this.workOderList[i].idNo =
                            (this.pageNo - 1) * this.pageSize + i + 1;
                    }
                    this.listLoading = false;
                },
                (err) => {
                    this.$message({
                        message: "数据请求失败",
                        type: "error",
                    });
                    this.listLoading = false;
                }
            );
        },

        findMyWorkOrder() {
            let para = {
                pageNo: this.pageNo,
                pageSize: this.pageSize,
                user_id: this.userInfo.id,
            };
            this.listLoading = true;
            findAllWorkOderPage(para).then(
                (resole) => {
                    let res = resole.data;
                    this.workOderList = res.list;
                    this.count = res.total;
                    for (var i = 0; i < this.workOderList.length; i++) {
                        this.workOderList[i].idNo =
                            (this.pageNo - 1) * this.pageSize + i + 1;
                    }
                    this.listLoading = false;
                },
                (err) => {
                    this.$message({
                        message: "数据请求失败",
                        type: "error",
                    });
                    this.listLoading = false;
                }
            );
        },
        // 跳转到详情页面
        toWorkOrderInfo: function (id) {
            this.$router.push({
                path: "/workOrder/workOrderInfo",
                query: { id: id },
            });
        },
        // 处理按钮触发函数
        deal(data) {
            this.dialogFormDeal = true;
            this.form.record_id = data.record_id;
            this.form.workId = data.id;
        },
        dealOk() {
            this.dialogFormDeal = false;
            let imglist = this.form.imgList;
            let imgString = imglist.toString();
            let uid = cookies.get("uid");
            let para = {
                status: "2",
                content: this.form.content,
                record_id: this.form.record_id,
                workOrder_id: this.form.workId,
                hand_id: uid,
                img_url: imgString,
            };
            handleWorkOrder(para).then((res) => {
                if (res.resultCode == 1) { 
                    this.$message({
                        message: res.resultMsg,
                        type: "success",
                    });
                } else if (res.resultCode == -1001) {
                    this.$message({
                        message: res.resultMsg,
                        type: "error",
                    });
                }
                this.dialogFormVisible = false;
            });
        },

        // 级联选择器选值改变时触发的函数
        change: function (value) {
            // 选择器发生改变之前先把处理人清空
            this.form.handId = "";
            this.form.departId = value[value.length - 1];
            var uid = cookies.get("uid");
            // 开始请求部门里的人员
            if (this.form.departId != null) {
                let para = {
                    dept_id: this.form.departId,
                };
                getAdminListBydept_id(para).then((res) => {
                    var admins = [];
                    for (var i = 0; i < res.length; i++) {
                        var a = {};
                        a.value = res[i].id;
                        a.label = res[i].name;
                        if (a.value == uid) {
                            a.disabled = true;
                        }

                        admins.push(a);
                    }
                    this.adminList = admins;
                });
            }
        },

        //部门列表信息
        getDeptInfoList: function () {
            getDeptInfoList().then((resData) => {
                if (resData.resultCode == 1) {
                    let res = resData.data;
                    let farr = [];
                    let sarr = [];
                    let tarr = [];
                    if (res.length > 0) {
                        let resData = res;
                        for (var i = 0; i < resData.length; i++) {
                            if (!farr[resData[i].fId]) {
                                farr[resData[i].fId] = {
                                    label: resData[i].fDeptName,
                                    value: resData[i].fId,
                                };
                            }
                            if (!sarr[resData[i].sId]) {
                                sarr[resData[i].sId] = {
                                    label: resData[i].sDeptName,
                                    value: resData[i].sId,
                                    parentId: resData[i].sParentId,
                                };
                            }
                            if (!tarr[resData[i].tId]) {
                                tarr[resData[i].tId] = {
                                    label: resData[i].tDeptName,
                                    value: resData[i].tId,
                                    parentId: resData[i].tParentId,
                                };
                            }
                        }
                        for (var i = 0; i < tarr.length; i++) {
                            if (tarr[i]) {
                                if (!sarr[tarr[i].parentId].children) {
                                    sarr[tarr[i].parentId].children = [];
                                }
                                sarr[tarr[i].parentId].children.push(tarr[i]);
                            }
                        }
                        for (var i = 0; i < sarr.length; i++) {
                            if (sarr[i]) {
                                if (!farr[sarr[i].parentId].children) {
                                    farr[sarr[i].parentId].children = [];
                                }
                                farr[sarr[i].parentId].children.push(sarr[i]);
                            }
                        }
                        this.deptList = [];
                        for (var i = 0; i < farr.length; i++) {
                            if (farr[i]) {
                                this.deptList.push(farr[i]);
                            }
                        }
                    }
                } else {
                    this.$message({
                        message: resData.resultMsg,
                        type: "error",
                    });
                }
            });
        },

        // 转发按钮触发函数
        forward(data) {
            this.forWordFormVisible = true;
            this.form.work_id = data.id;
            this.form.record_id = data.record_id;
            this.form.status = data.status;
            this.form.content = data.content;
            // 请求部门列表
            this.getDeptInfoList();
        },
        // 转发提交
        submitOk: function () {
            let imglist = this.form.imgList;
            let imgString = imglist.toString();
            let uid = cookies.get("uid");

            var handid = this.form.handId;

            let para = {
                workOrder_id: this.form.work_id,
                status: "3",
                content: this.form.content,
                hand_id: this.form.handId,
                record_id: this.form.record_id,
                img_url: imgString,
            };
            handleWorkOrder(para).then((res) => {
                if (res.resultCode == 1) {
                    this.$message({
                        message: res.resultMsg,
                        type: "success",
                    });
                } else if (res.resultCode == -1001) {
                    this.$message({
                        message: res.resultMsg,
                        type: "error",
                    });
                }

                this.dialogFormVisible = false;
            });
        },

        handlePictureCardPreview(file) {
            this.dialogImageUrl = file.url;
            this.dialogVisible = true;
        },
        handleDownload(file) {
            console.log(file);
        },
        // 上传成功
        handleImageSuccess(res, file, fileList) {
            // 上传成功
            // this.dialogImageUrl = fileList;
            var url = "http://" + res.data.url + "";
            console.log("上传成功" + fileList.length);
            console.log("返回图片路径：" + url);
            console.log("图片列表", this.form.imgList);
            this.form.imgList.push(url);
        },
        uploadError(res) {
            console.log("上传失败" + res);
        },
        handlePreview(file) {
            console.log(file);
        },

        // 跳转页面
        to_mamage: function (p) {
            if (p == "all_work") {
                this.$router.push({ path: "/workOrder/index" });
            } else {
                this.doQuery(p);
            }
        },
        // 标签页触发函数
        handleClick(tab, event) {
            if (this.type_title == "my_order") {
                this.findMyWorkOrder();
            } else if (this.type_title == "my_hand") {
                this.doQuery(1);
            }
        },
        // 上传图片移除触发函数
        handleRemove(file, fileList) {
            console.log(file, fileList);
        },
        // 提交添加工单
        addAdmin: function (form) {
            this.titleAdmin = "添加";
            this.dialogFormVisible = true;
            let uid = cookies.get("uid");
            let user = JSON.parse(localStorage.getItem("userInfo" + uid));

            if (form) {
                this.$confirm("确认提交吗？", "提示", {}).then(() => {
                    let imglist = this.form.imgList;
                    let imgString = imglist.toString();

                    let para = {
                        title: this.form.title,
                        content: this.form.content,
                        type: this.form.type,
                        user_id: uid,
                        user_name: user.name,
                        level: this.form.level,
                        dept_id: this.form.departId,
                        hand_id: this.form.handId,
                        imgList: imgString,
                    };

                    addWorkOder(para).then((res) => {
                        this.$message({
                            message: "添加成功！",
                            type: "success",
                        });
                        this.dialogFormVisible = false;
                    });
                });
            }
        },
        //部门列表信息
        getDeptInfoList: function () {
            getDeptInfoList().then((resData) => {
                if (resData.resultCode == 1) {
                    let res = resData.data;
                    let farr = [];
                    let sarr = [];
                    let tarr = [];
                    if (res.length > 0) {
                        let resData = res;
                        for (var i = 0; i < resData.length; i++) {
                            if (!farr[resData[i].fId]) {
                                farr[resData[i].fId] = {
                                    label: resData[i].fDeptName,
                                    value: resData[i].fId,
                                };
                            }
                            if (!sarr[resData[i].sId]) {
                                sarr[resData[i].sId] = {
                                    label: resData[i].sDeptName,
                                    value: resData[i].sId,
                                    parentId: resData[i].sParentId,
                                };
                            }
                            if (!tarr[resData[i].tId]) {
                                tarr[resData[i].tId] = {
                                    label: resData[i].tDeptName,
                                    value: resData[i].tId,
                                    parentId: resData[i].tParentId,
                                };
                            }
                        }
                        for (var i = 0; i < tarr.length; i++) {
                            if (tarr[i]) {
                                if (!sarr[tarr[i].parentId].children) {
                                    sarr[tarr[i].parentId].children = [];
                                }
                                sarr[tarr[i].parentId].children.push(tarr[i]);
                            }
                        }
                        for (var i = 0; i < sarr.length; i++) {
                            if (sarr[i]) {
                                if (!farr[sarr[i].parentId].children) {
                                    farr[sarr[i].parentId].children = [];
                                }
                                farr[sarr[i].parentId].children.push(sarr[i]);
                            }
                        }
                        this.deptList = [];
                        for (var i = 0; i < farr.length; i++) {
                            if (farr[i]) {
                                this.deptList.push(farr[i]);
                            }
                        }
                    }
                } else {
                    this.$message({
                        message: resData.resultMsg,
                        type: "error",
                    });
                }
            });
        },
        // 级联选择器选值改变时触发的函数
        change: function (value) {
            // 选择器发生改变之前先把处理人清空
            this.form.handId = "";
            this.form.departId = value[value.length - 1];
            // 开始请求部门里的人员
            if (this.form.departId != null) {
                let para = {
                    dept_id: this.form.departId,
                };
                getAdminListByDeptId(para).then((resData) => {
                    if (resData.resultCode == 1) {
                        var res = resData.data;
                        var admins = [];
                        for (var i = 0; i < res.length; i++) {
                            var a = {};
                            a.value = res[i].id;
                            a.label = res[i].name;
                            admins.push(a);
                        }
                        this.adminList = admins;
                    } else {
                        this.$message({
                            message: resData.resultMsg,
                            type: "error",
                        });
                    }
                });
            }
        },
        // 获取工单类型字典
        getorgTypeDic: function () {
            let para = {
                classifyCode: "workType",
            };
            getSysDictionaryList(para).then((result) => {
                this.org_type_list = result.data;
                console.log("工单类型字典列表", this.org_type_list);
            });
        },

        // 获取紧急级别字典
        getWorkLevelDic: function () {
            let para = {
                classifyCode: "workLevel",
            };
            getSysDictionaryList(para).then((result) => {
                this.work_level_list = result.data;
                console.log("紧急级别字典列表", this.work_level_list);
            });
        },

        // 获取工单状态字典
        getWorkStatusDic: function () {
            let para = {
                classifyCode: "workStatus",
            };
            getSysDictionaryList(para).then((result) => {
                this.work_status_list = result.data;
                console.log("字典列表", this.work_level_list);
            });
        },
    },
};
</script>

<style lang="scss">
.admin-index {
    .el-table thead {
        color: #21293b;
        font-size: 0.8rem;
    }
    .el-table th,
    .el-table thead {
        background-color: #e9ecee;
        height: 3rem;
    }
    .el-table {
        font-size: 0.8rem;
    }
    .el-table td, .el-table th {
    text-align: center;
} 
}
</style>